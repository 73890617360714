<template>
  <div id="section1" class="section section-grid active section-nav" :class="{ 'loaded': isLoaded }">
    <div class="grid-item grid-item-12">
      <div>
        <div>
          <h1>Gentle Monster<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>IICombined Philippines</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>445 sqm; Shangri-la at the Fort, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <img src="@/assets/img/landing/7.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-13">
      <div>
        <div>
          <h1>Gentle Monster<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>IICombined Philippines</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>445 sqm; Shangri-la at the Fort, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/7.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-11">
      <div>
        <div>
          <h1>Gentle Monster<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>IICombined Philippines</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>445 sqm; Shangri-la at the Fort, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/6.0.jpg" alt="">
    </div>

    <div class="grid-item grid-item-27">
      <div>
        <div>
          <h1>Arabica Mitsukoshi<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Khoffee Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>140sqm; Mitsukoshi Mall BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>AOR</span>
                <span>Post Construction</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/15.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-28">
      <div>
        <div>
          <h1>Arabica Mitsukoshi<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Khoffee Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>140sqm; Mitsukoshi Mall BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>AOR</span>
                <span>Post Construction</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/15.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-29">
      <div>
        <div>
          <h1>Arabica BGC High Street<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Khoffee Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200sqm; Mitsukoshi Mall BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>AOR</span>
                <span>Post Construction</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/16.0.jpg" alt="">
    </div>
    <div class="grid-item grid-item-30">
      <div>
        <div>
          <h1>Arabica BGC High Street<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Khoffee Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200sqm; Mitsukoshi Mall BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>AOR</span>
                <span>Post Construction</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/17.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-31">
      <div>
        <div>
          <h1>Arabica BGC High Street<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Khoffee Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200sqm; Mitsukoshi Mall BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>AOR</span>
                <span>Post Construction</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/17.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-41">
      <div>
        <div>
          <h1>Hungry Whale SM Dasmarinas<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Fear the Deer</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>50 sqm; SM Dasmarina</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/5.1.jpg" alt="">
    </div>

    <div class="grid-item grid-item-42">
      <div>
        <div>
          <h1>Hungry Whale SM Dasmarinas<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Fear the Deer</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>50 sqm; SM Dasmarina</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/4.3.jpg" alt="">
    </div>

    <div class="grid-item grid-item-43">
      <div>
        <div>
          <h1>Hungry Whale Pop-Up Store<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Fear the Deer</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>12 sqm; SM North, QC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/4.4.jpg" alt="">
    </div>

    <div class="grid-item grid-item-6">
      <div>
        <div>
          <h1>Hungry Whale Pop-Up Store<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Fear the Deer</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>12 sqm; SM North, QC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/4.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-7">
      <div>
        <div>
          <h1>Hungry Whale Pop-Up Store<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Fear the Deer</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>12 sqm; SM North, QC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/4.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-44">
      <div>
        <div>
          <h1>Art<span class="text-red">+</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>ArtPlus Concepts Inc.</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>96 sq.m. at Shangri-la at the Fort, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/5.3.jpg" alt="">
    </div>
    <div class="grid-item grid-item-10">
      <div>
        <div>
          <h1>Art<span class="text-red">+</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>ArtPlus Concepts Inc.</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>96 sq.m. at Shangri-la at the Fort, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Retail</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/5.0.jpg" alt="">
    </div>

    <!-- <div class="grid-item grid-item-1"><div><h1>Project A<span class="text-red">.</span></h1></div><img src="@/assets/img/landing/1.1.jpg" alt=""></div> -->
    <!-- <div class="grid-item grid-item-3">
      <div>
        <h1>Project A<span class="text-red">.</span></h1>
      </div><img src="@/assets/img/landing/2.1.jpg" alt="">
    </div> -->
    <div class="grid-item grid-item-4">
      <div>
        <div>
          <h1>The Kho Group<br> CEO Office<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>100 sqm; Menarco Tower, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Office</span>
                <span>Full Regular Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/2.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-1">
      <div>
        <div>
          <h1>The Kho Group<br> CEO Office<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>100 sqm; Menarco Tower, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Office</span>
                <span>Full Regular Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/1.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-2">
      <div>
        <div>
          <h1>TKG Office<br> Vanity Project<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>100 sqm; Menarco Tower, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Office</span>
                <span>Full Regular Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/3.0.jpg" alt="">
    </div>
    <!-- <div class="grid-item grid-item-5"><div><h1>The Kho Group<span class="text-red">.</span></h1></div><img src="@/assets/img/landing/3.0.jpg" alt=""></div> -->

    <div class="grid-item grid-item-25">
      <div>
        <div>
          <h1>44B - Revisualisation<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>100sqm++; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/14.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-26">
      <div>
        <div>
          <h1>44B - Revisualisation<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>100sqm++; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/14.2.jpg" alt="">
    </div>


    <div class="grid-item grid-item-14">
      <div>
        <div>
          <h1>Learning Lane<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Alphabet, Inc.</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200s sqm;</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Education</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/8.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-15">
      <div>
        <div>
          <h1>Learning Lane<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Alphabet, Inc.</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200s sqm;</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Education</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/8.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-16">
      <div>
        <div>
          <h1>SOMA North Tower<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Amy Luk</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>76 sqm; South of Market, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Design Services</span>
                <span>Full Projectv Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/8.3.jpg" alt="">
    </div>

    <div class="grid-item grid-item-17">
      <div>
        <div>
          <h1>SOMA North Tower<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Amy Luk</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>76 sqm; South of Market, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Design Services</span>
                <span>Full Projectv Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/9.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-18">
      <div>
        <div>
          <h1>SOMA North Tower<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Amy Luk</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>76 sqm; South of Market, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Design Services</span>
                <span>Full Projectv Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/9.2.jpg" alt="">
    </div>

    <!-- <div class="grid-item grid-item-24"><div><h1>Project C<span class="text-red">.</span></h1></div><img src="@/assets/img/landing/13.0.jpg" alt=""></div> -->
    <div class="grid-item grid-item-22">
      <div>
        <div>
          <h1>Rizal Townhouse<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>sqm++; Rizal</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/12.1.jpg" alt="">
    </div>

    <div class="grid-item grid-item-23">
      <div>
        <div>
          <h1>Rizal Townhouse<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>sqm++; Rizal</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/12.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-25">
      <div>
        <div>
          <h1>Rizal Townhouse<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>sqm++; Rizal</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/12.3.jpg" alt="">
    </div>

    <div class="grid-item grid-item-26">
      <div>
        <div>
          <h1>Rizal Townhouse<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>sqm++; Rizal</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/12.4.jpg" alt="">
    </div>

    <div class="grid-item grid-item-19">
      <div>
        <div>
          <h1>Pretty HUGE<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>2000 sqm; SM Aura, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Lifestyle</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/10.0.jpg" alt="">
    </div>

    <div class="grid-item grid-item-20">
      <div>
        <div>
          <h1>Pretty HUGE<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>2000 sqm; SM Aura, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Lifestyle</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/11.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-21">
      <div>
        <div>
          <h1>Pretty HUGE<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>The Kho Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>2000 sqm; SM Aura, BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Lifestyle</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/11.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-32">
      <div>
        <div>
          <h1>44B<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>80sqm; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/18.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-33">
      <div>
        <div>
          <h1>44B<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>80sqm; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/18.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-34">
      <div>
        <div>
          <h1>44B<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>80sqm; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/18.3.jpg" alt="">
    </div>

    <div class="grid-item grid-item-35">
      <div>
        <div>
          <h1>Bayanan Apartment<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>*sqml; Muntinlupa</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/19.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-36">
      <div>
        <div>
          <h1>Bayanan Apartment<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>*sqml; Muntinlupa</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/19.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-5">
      <div>
        <div>
          <h1>Garden House<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/21.0.jpg" alt="">
    </div>

    <div class="grid-item grid-item-35">
      <div>
        <div>
          <h1>CXRGILL<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Office</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/20.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-36">
      <div>
        <div>
          <h1>CXRGILL<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Office</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/20.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-32">
      <div>
        <div>
          <h1>Isla Competition <br>Line Chair<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential - Competition</span>
                <span>Concept</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/23.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-33">
      <div>
        <div>
          <h1>Isla Competition <br>Line Island<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential - Competition</span>
                <span>Concept</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/23.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-34">
      <div>
        <div>
          <h1>Isla Competition <br>Line Workstation<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential - Competition</span>
                <span>Concept</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/23.3.jpg" alt="">
    </div>

    <div class="grid-item grid-item-35">
      <div>
        <div>
          <h1>Isla Competition<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential - Competition</span>
                <span>Concept</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/22.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-36">
      <div>
        <div>
          <h1>Isla Competition<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>-sqm</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential - Competition</span>
                <span>Concept</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/22.2.jpg" alt="">
    </div>



    <div class="grid-item grid-item-37">
      <div>
        <div>
          <h1>P. Cruz House<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>420sqm++; Mandaluyong</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/24.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-38">
      <div>
        <div>
          <h1>P. Cruz House<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>420sqm++; Mandaluyong</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/24.2.jpg" alt="">
    </div>



    <div class="grid-item grid-item-39">
      <div>
        <div>
          <h1>Ruby House<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>300sqm++; Bataan</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/26.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-40">
      <div>
        <div>
          <h1>Ruby House<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>300sqm++; Bataan</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Visualisation</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/26.2.jpg" alt="">
    </div>
    <div class="grid-item grid-item-9">
      <div>
        <div>
          <h1>Agave Mexican Cantina<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>Altamira Group</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>200 sqm; Sta. Lucia , Rizal</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>F&B</span>
                <span>Full Design Services</span>
                <span>Full Project Management</span>
              </td>
            </tr>
          </table>
        </div>

      </div><img src="@/assets/img/landing/5.2.jpg" alt="">
    </div>

    <div class="grid-item grid-item-38">
      <div>
        <div>
          <h1>PPTPP - Initial Visualisation<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>600sqm++; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/25.1.jpg" alt="">
    </div>
    <div class="grid-item grid-item-37">
      <div>
        <div>
          <h1>PPTPP - Initial Visualisation<span class="text-red">.</span></h1>
          <table>
            <tr>
              <td>
                <p>Client</p>
              </td>
              <td><span>-</span></td>
            </tr>
            <tr>
              <td>
                <p>Size and Location</p>
              </td>
              <td><span>600sqm++; BGC</span></td>
            </tr>
            <tr>
              <td>
                <p>Typology and Scope</p>
              </td>
              <td>
                <span>Residential</span>
                <span>Full Architectural Design</span>
                <span>Full Project Management</span>
                <span>Post Construction Management</span>
              </td>
            </tr>
          </table>
        </div>
      </div><img src="@/assets/img/landing/25.2.jpg" alt="">
    </div>
    <button class="btn-scroll-top" :class="{ 'show': isShow }" @click="scrollToTop"><img src="@/assets/img/icon-up.svg" alt=""></button>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import '@/assets/sass/4-pages/landing.sass'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Footer
  },
  data() {
    return {
      isLoaded: false,
      isShow: false
    };
  },
  mounted() {
    // Access the element using the ref
    const thisSection = this.$refs.thisSection;
    // const isLoaded = false;
    console.log(this.isLoaded);

    window.addEventListener('scroll', this.handleScroll);

    // Add a class to the element
    // thisSection.classList.add('loaded');

    setTimeout(() => {
      this.isLoaded = true;
      console.log(this.isLoaded);
      // thisSection.classList.add('loaded');
    }, 250);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isShow = window.scrollY !== 0;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
